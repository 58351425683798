import React, {useContext} from 'react'
import { Helmet } from "react-helmet"
import App from '../components/App'
import { navigate } from 'gatsby'
import { Redirect } from '@reach/router'
import { LangugaeContext } from "../context/LanguageProvider";
import SEO from "../components/shared/seo"

//const language = useContext(LangugaeContext);
class IndexPage extends React.Component {

  componentDidMount() {
   // let lang = language.swedish ? 'en' : 'sv';
    navigate(`/sv`); // TODO: navigate depending on where the person is
  }

  render() {
    return (
    <div style={{height: "100vh", width: "100vw", background: 'black'}}>
        <SEO 
            title="Zimply"
            description="Automatisera med digitala assistenter. Få bort tidskrävande arbetsuppgifter" //{cmsData.seo.descr == "-" ? "" : cmsData.seo.descr}
        />
    </div>);
  }
}

// markup
/*const IndexPage = () => 
    <Redirect to="/sv" />
    /*return (
      <>
      <Helmet>
        <script src="https://identity.netlify.com/v1/netlify-identity-widget.js"></script>
        <script charset="utf-8" type="text/javascript" src="//js-eu1.hsforms.net/forms/v2.js"></script>
      </Helmet>
      <main>
        <App />
      </main>
      </>
    )*/
  
  
  export default IndexPage